import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
// import './hfstyle.css';
import './bootstrap.min.css';
import { HashLink } from 'react-router-hash-link';
import esoLogo from './../../images/logo/eso-logo.png'
import { useState, useEffect } from 'react';
import BannerContent  from '../../pages/Banner/BannerContent';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
   
const NavBar = () => {
  const navigate = useNavigate();

  const freeTrialSelect = "?subject=free-trial-request"

  const [urlValue, setUrlValue] = useState(document.location.search)

  useEffect(() => {
    if(urlValue === freeTrialSelect){
      document.getElementById("select").value = "Free Trial Request";
    } 
    getGeoInfo()
  })
  const getGeoInfo = () => {

    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
    if(data.country_code!="US" &&  data.country_code!="IN"){
      navigate("/error-page")

    }
    }).catch((error) => {
        console.log(error);
    });
};

  const changeSelect = () => {
    setUrlValue(document.location.search)
  }

  const scrollMe = () => {
    window.scroll(0,0)
  }

  return (
    <div>
          <div id="page-container" className="et-animated-content">
      <header id="main-header" style={{ top: '0px' }} className="main1_header new_header_outer_wrap">
        <nav  id='navBar' className="navbar sticky-top navbar-expand-lg navbar-light" style={{position: 'sticky'}}>
          <div className="container clearfix et_menu_container">
            <HashLink className="navbar-brand" to="/" elementId='root'>
              <img src="https://esowebsitekb.s3.amazonaws.com/esowebsitekb/eso-logo.png" alt="SEO Automation Tool" id="logo" width="auto" height="auto" />
            </HashLink> <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon" /> </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto">
                {/* <li className="nav-item dropdown cl_service">
                  <HashLink className="nav-link dropdown-toggle" to="/services/" elementId='root'>Services</HashLink>
                  <ul className="sub-menu dropdown-menu">
                    <li className="nav-item dropdown cl_digital ">
                      <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/services/digital-marketing-services/">Digital Marketing</HashLink>
                      <ul className="dropdown-menu dropdown_menu">
                        <li id="perfor_mid" className="menu_item  "><HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/services/digital-marketing-services/performance-based-seo/" elementId='root'>Performance Based SEO</HashLink></li>
                       
                      </ul>
                    </li>

                    <li className="nav-item dropdown cl_custom_craw "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/services/custom-crawler/" elementId='root'>Custom Crawler</HashLink></li>
                    <li className="nav-item dropdown cl_web_maint "> <a className="nav-link dropdown-toggle dropdown_toggle" target="&quot;_blank&quot;" href="https://www.egrovesys.com/website-maintenance-services/">Website Maintenance</a></li>
                  </ul>
                </li> */}
                <li className="nav-item dropdown cl_features  " style={{marginRight:"35px"}}>
                  <HashLink className="nav-link dropdown-toggle" to="/features/" elementId='root'>Features</HashLink>
                  <ul className="sub-menu dropdown-menu features-menu">
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/seo-dashboard/" elementId='root'>SEO Dashboard</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/webpage-seo-analysis/"  elementId='root'>Performance Analysis</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/keyword-ranking-checker/"  elementId='root'>Rank Analysis</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/on-page-seo-Analysis/"  elementId='root'>On-Page SEO Analysis</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/internal-link-checker/" elementId='root'>Link Checker</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/schema-validator/"  elementId='root'>Schema Validator</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/traffic-analytics/"  elementId='root'>Analytics Console</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/sitemap-generator/"  elementId='root'>Sitemap Generator</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/content-audit/"  elementId='root'>Content Audit</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/plagiarism-checker/" elementId='root'>Plagiarism Checker</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/features/accessibility-checker/" elementId='root'>Accessibility Checker</HashLink></li>

                  </ul>
                </li>
                {/*      <li class="nav-item dropdown cl_pricing  ">
                    <a class="nav-link dropdown-toggle" href="#">Pricing</a>
                    <ul class="sub-menu dropdown-menu">
                        <li class="nav-item dropdown  "> <a class="nav-link dropdown-toggle dropdown_toggle"  href="https://www.elitesiteoptimizer.com/plans-and-pricing/">Elite Site Optimizer</a>  </li>
                        <li class="nav-item dropdown  "> <a class="nav-link dropdown-toggle dropdown_toggle"  href="https://www.elitesiteoptimizer.com/seo-tools/">SEO Tools</a>  </li>
                    </ul>
                  </li> */}
                <li className="nav-item dropdown cl_aboutus  " style={{marginRight:"35px"}}>
                  <HashLink className="nav-link dropdown-toggle" to="/about-us/" elementId='root'>About Us</HashLink>
                </li>
                {/* <li className="nav-item dropdown cl_blogs  ">
                  <HashLink to="/blogs/" elementId='root' className="nav-link dropdown-toggle" target="_blank">Blog</HashLink>
                </li>
                <li className="nav-item dropdown cl_features  ">
                  <HashLink className="nav-link dropdown-toggle" to="">Become a Partner</HashLink>
                  <ul className="sub-menu dropdown-menu">
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/white-label-partner/" elementId='root'>White Label Partner</HashLink></li>
                    <li className="nav-item dropdown  "> <HashLink className="nav-link dropdown-toggle dropdown_toggle" to="/solution-partner/" elementId='root'>Solution Partner</HashLink></li>
                  </ul>
                </li> */}
                <li className="nav-item dropdown cl_contactus  " style={{marginRight:"35px"}}>
                  <HashLink className="nav-link dropdown-toggle" to="/contact-us/" elementId='root'>Contact Us</HashLink>
                </li>
                <li className="nav-item dropdown cl_login login_nav  " style={{marginRight:"35px"}}>
                  {/* <HashLink className="nav-link dropdown-toggle" to="/accounts/login/" elementId='root'>Login</HashLink> */}
                  <a className="nav-link dropdown-toggle" href="https://v3.elitesiteoptimizer.com/" target='_blank'>Login</a>
                  {/*   <ul class="sub-menu dropdown-menu">
                        <li class="nav-item dropdown  "> <a class="nav-link dropdown-toggle dropdown_toggle"  href="https://www.elitesiteoptimizer.com/my-account/">My Account</a>  </li>
                        <li class="nav-item dropdown  "> <a class="nav-link dropdown-toggle dropdown_toggle"  href="">Application Login</a>  </li>
                    </ul> */}
                </li>
                {/* <li className="nav-item dropdown cl_getfreetrial login_getfree btn  ">
                  <HashLink className="nav-link dropdown-toggle header_free_trial_btn_wrap" to="/contact-us/?subject=free-trial-request" elementId="contact_form_test" ><a onClick={changeSelect}>Get Free Trial</a></HashLink>
                </li> */}
                   <li className="nav-item dropdown cl_getfreetrial login_getfree_btn ">
                  <HashLink className="nav-link dropdown-toggle header_free_trial_btn_wrap" to="/plans-and-pricing" elementId="contact_form_test" onClick={changeSelect} ><b style={{color: '#052A53'}}>Plans and Pricing</b></HashLink>
                </li>
              </ul>
              <form className="form-inline my-2 my-lg-0" style={{ display: 'none' }}>
                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
              </form>
            </div>
          </div>
        </nav>
      </header>
    </div>
    <BannerContent/>
    </div>
  )
}

export default NavBar;
